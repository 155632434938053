module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5e807ff3bde81988bf3720d117facdc5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-i18n-l10n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLocale":"en","siteUrl":"https://www.tpi-technology.com","locales":[{"locale":"en","prefix":"en","slugs":{},"messages":{"language":"English"}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":" GTM-K3JFXTDW","cookieName":"gatsby-gdpr-google-tagmanager","anonymize":true,"allowAdFeatures":false},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
